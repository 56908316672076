////////////////////////////////////////////////////////////////////////////////
// Forms
////////////////////////////////////////////////////////////////////////////////
// =============================================================================
// INPUTS
// =============================================================================

.app-form-title {
    margin-top: 40px;
    margin-bottom: 0;
    text-transform: uppercase;

}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="url"] {
    border: 1px solid $grey2;
    border-radius: 5px;
    line-height: 40px;
    width: 100%;
}

application-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $gap2 0;

    // Input switcher for Reg or Serial
    input-switcher {
        $switcher-width: 300px;
        $switcher-radius: 1.666666em;

        background-color: transparentize($color: $bahama-blue, $amount: 0.75);
        border: 1px solid transparentize($color: $bahama-blue, $amount: 0.75);
        border-radius: $switcher-radius;
        overflow: hidden;
        width: $switcher-width;
        text-align: center;
        font-size: 18px;
        color: $bahama-blue;
        position: relative;
        padding-right: $switcher-width / 2;
        margin-bottom: $gap;
        cursor: pointer;
        transition: background-color .3s ease-in-out;

        &:hover, &:active, &:focus {
            background-color: transparentize($color: $bahama-blue, $amount: 0.65);
        }

        &:before {
            content: 'Caravan';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            padding: 15px 0;
            width: $switcher-width / 2;
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3;
            pointer-events: none;
        }

        input {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 2;

            &:checked+label:before {
                transform: translateX($switcher-width / 2);
                transition: transform .3s linear;
            }
        }

        label {
            position: relative;
            padding: 15px 0;
            display: block;
            user-select: none;
            pointer-events: none;

            &:before {
                content: '';
                background-color: $white;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                border-radius: $switcher-radius;
                transform: translateX(0);
                transition: transform .3s;
            }

            span {
                position: relative;
            }
        }
    }

    input-fields {
        position: relative;
        text-align: center;
        display: block;
    }

    hr {
        opacity: 0.2;
    }

    // Multi-submit button form container styles
    .submits {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 32px;
        width: 100%;
        max-width: 100vw;

        >p {
            margin: $gap $gap2 0;
            font-size: 21px !important;
            line-height: 1;
            padding: 0.5714em 0;
            font-weight: 700;
            color: $bahama-blue;
        }

        >input,
        .applyByPhone {
            max-width: 100%;
        }

        input {
            margin-top: $gap;
        }

        .applyByPhone {
            color: $thunder;
            display: flex;
            flex-direction: column;
            position: relative;

            label {
                margin-top: 12px;
                font-size: 12px;
                list-style: none;
                line-height: 1.4;
            }

            select {
                margin-top: 12px;
                border: 1px solid $bahama-blue;
                background: transparent;
                color: $bahama-blue;
                padding: 2px 4px;
                border-radius: 4px;
                line-height: 24px;
            }
        }

        @include break($medium, '>') {
            width: 100%;
            flex-direction: row;
            align-items: flex-start;

            >input,
            .applyByPhone {
                width: 100%;
                max-width: 48%;
            }
        }

        @include break($large, '>') {
            width: inherit;
        }
    }
}

input-fields {

    /* Base for label styling */
    [type="checkbox"]:checked,
    [type="checkbox"]:not(:checked) {
        position: absolute;
        //left: -9999px;
        opacity: 0;
    }

    [type="checkbox"]:checked+label,
    [type="checkbox"]:not(:checked)+label {
        position: relative;
        padding-left: 2.1333em;
        cursor: pointer;
        display: inline-block;
        font-size: inherit;
    }

    /* checkbox aspect */
    [type="checkbox"]:checked+label:before,
    [type="checkbox"]:not(:checked)+label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 1.5em;
        height: 1.5em;
        border-width: 1px;
        border-style: solid;
        border-color: $bahama-blue;
        background-color: $white;
        border-radius: 5px;
        transform: translateY(-50%);
        //box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
    }

    /* checked mark aspect */
    [type="checkbox"]:checked+label:after,
    [type="checkbox"]:not(:checked)+label:after {
        content: '✔';
        position: absolute;
        top: 50%;
        left: 0;
        font-size: 1.5em;
        line-height: 1;
        height: 1em;
        width: 1em;
        transform: translateY(-50%);
        color: $mantis;
        transition: opacity 0.2s, transform 0.2s;
        margin: -0.5em 0.1em;
    }

    /* checked mark aspect changes */
    [type="checkbox"]:not(:checked)+label:after {
        opacity: 0;
        transform: scale(0.4);
    }

    [type="checkbox"]:checked+label:after {
        opacity: 1;
        transform: scale(1);
    }

    /* disabled checkbox */
    [type="checkbox"]:disabled:checked+label:before,
    [type="checkbox"]:disabled:not(:checked)+label:before {
        box-shadow: none;
        border-color: #999999;
        background-color: #dddddd;
    }

    [type="checkbox"]:disabled:checked+label:after {
        color: #999999;
    }

    [type="checkbox"]:disabled+label {
        color: #aaaaaa;
    }

    label {
        transition: color 300ms;
    }

    label:before {
        transition: color 300ms;
    }

    label:hover {
        color: $grey2;
    }

    // General styles
    .inputs {
        a {
            color: $bahama-blue;
        }
    }

    // Input containers
    &>div,
    .checkboxes {
        margin: 0 $gap0 2%;

        &.inputs {
            width: 100%;
            user-select: none;
            padding: 10px 0;
            font-size: 13px;
            text-align: left;
            margin-bottom: 0;
        }
    }

    // Checkbox list
    .checkboxes {
        color: $dusty-gray;
        font-size: 13px;
        max-width: 100%;

        .inputs {
            max-width: 100%;
            margin: 16px 0 0 0;
            text-align: left;
        }

        label:hover:before {
            border-color: $bahama-blue;
        }
    }

    .radios {
        flex-basis: auto;
        max-width: 100%;
        width: 100%;
        color: $thunder;

        .inputs {
            display: inline-flex;

            legend {
                font-size: 1.2em;
                font-weight: 400;
            }

            label {
                color: $thunder;
                margin-left: $gap2;
                margin-top: $gap;
            }
        }

        .errors {
            text-align: center;
            margin-top: $gap0;
        }
    }

    @include break($medium, '>') {
        .radios {
            .inputs {
                legend {
                    display: contents;
                }

                label {
                    margin-top: 0;
                }
            }
        }
    }

    @include break($medium, '>') {
        >div {
            max-width: calc(50% - #{$gap});
            flex-basis: 50%;
            margin-bottom: $gap;
        }

        .checkboxes {
            display: flex;
            justify-content: space-between;
            max-width: 100%;

            >div {
                margin-left: 0;
            }

            .inputs {
                margin-top: 0;

                &+.inputs {
                    margin-left: $gap;
                }
            }
        }
    }
}

input.large, select.large {
    background-color: $bahama-blue;
    color: $white;
    max-width: 100%;
    height: 60px;
    border: 1px solid $white;
    padding: 5px 15px;
    @include border-radius(5);
    line-height: 60px;
    position: relative;

    &:active,
    &:focus,
    &:hover {
        border-color: $black;
    }

    &::-webkit-input-placeholder {
        text-transform: none;
        font-size: 16px;
        font-weight: 400;
        color: $white;
        font-family: 'Arial';
    }

    &::-moz-placeholder {
        text-transform: none;
        font-size: 16px;
        font-weight: 400;
        color: $white;
        font-family: 'Arial';
    }

    &:-ms-input-placeholder {
        text-transform: none;
        font-size: 16px;
        font-weight: 400;
        color: $white;
        font-family: 'Arial';
    }

    &:-moz-placeholder {
        text-transform: none;
        font-size: 16px;
        font-weight: 400;
        color: $thunder;
        font-family: 'Arial';
    }

    &.reg-input {
        background-color: $web-orange;
        color: $black;
        border-color: transparent;

        &::-webkit-input-placeholder {
            color: $black;
            font-weight: bold;
        }

        &::-moz-placeholder {
            color: $black;
            font-weight: bold;
        }

        &:-ms-input-placeholder {
            color: $black;
            font-weight: bold;
        }

        &:-moz-placeholder {
            color: $black;
            font-weight: bold;
        }
    }

    &.serial-input {
        background-color: $white;
        color: $black;
        border-color: $bahama-blue;

        &::-webkit-input-placeholder {
            color: $black;
            font-weight: bold;
            line-height: 1;
        }

        &::-moz-placeholder {
            color: $black;
            font-weight: bold;
            line-height: 1;
        }

        &:-ms-input-placeholder {
            color: $black;
            font-weight: bold;
            line-height: 1;
        }

        &:-moz-placeholder {
            color: $black;
            font-weight: bold;
            line-height: 1;
        }
    }

    &.reg-input,
    &.mileage-input,
    &.value-input {
        @extend .mandator;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 35px;
    }

    &.serial-input {
        @extend .monospace;
        text-transform: uppercase;
        font-size: 35px;
    }
}

.reg-wrap,
.serial-wrap {
    display: none;
    opacity: 0;
    transition: opacity .4s ease-in-out;

    &.visible {
        display: block;
        opacity: 1;
    }
}

[data-browser*='edge'] {
    input.large {
        line-height: 0;
    }
}

// Safari does wierd things with placeholder positioning
html:not([data-browser*='safari']) {
    input.large[class*="-input"] {
        &::-webkit-input-placeholder {
            transform: translateY(-50%);
        }

        &::-moz-placeholder {
            transform: translateY(-50%);
        }

        &:-ms-input-placeholder {
            transform: translateY(-50%);
        }

        &:-moz-placeholder {
            transform: translateY(-50%);
        }
    }
}

@include break($medium, '>') {
    application-form {
        input-fields {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;

            >div {
                // max-width: 31%;
                margin-left: auto;
                margin-right: auto;
                // flex-basis: 33.3%;
                flex-grow: 1;
                flex-shrink: 0;
            }
        }
    }
}

@include break($large, '>') {
    application-form {
        padding: 50px 0;
    }
}


.select-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    select {
        width: 100%;
        -webkit-appearance: none;
        appearance: none;

        &.large { 
            padding-top: 0px;
            padding-right: 30px;
        }

        option {
            vertical-align: middle;
        }
    }

    &::after {
        content: "▼";
        font-size: 1.25rem;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        color: white;
        position: absolute;
        pointer-events: none;
    }
}
