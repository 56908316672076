main>representative-example {
    display: block;
    background-color: $zumthor;
    text-align: center;
    padding: $gap2;

    h5 {
        color: $bahama-blue;
        font-weight: 700;
        text-transform: uppercase;
    }

    p {
        color: $bahama-blue;
    }
}

content-aside + representative-example {
    margin-top: $gap2;
}
