////////////////////////////////////////////////////////////////////////////////
// Navigation Bar
////////////////////////////////////////////////////////////////////////////////

navigation-bar {
    display: block;
    position: absolute;
    top: $sticky-height;
    right: 0;
    background: $blue;
    z-index: 99;
    max-width: 350px;
    width: 100%;
    height: auto;
    pointer-events: none;
    opacity: 0;
    transition: opacity 300ms;
    background-color: $mantis;

    >a {
        position: absolute;
        display: block;
        width: 50px;
        height: 55px;
        right: 0px;
        top: -55px;
        line-height: 55px;
        text-align: center;
        color: $white;
        font-size: 55px;
        z-index: 999;
        transition: 300ms;
    }

    ul {
        li {
            border-bottom: 1px solid rgba($white, 0.6);

            &:last-of-type {
                border-bottom: none;
            }

            a {
                display: block;
                color: $white;
                font-size: 17px;
                font-weight: 600;
                text-transform: uppercase;
                line-height: 55px;
                padding: 0 25px;
                transition: 300ms;
                white-space: nowrap;

                &:hover {
                    background-color: darken($bahama-blue, 10%);
                }

                span {
                    text-transform: lowercase;
                }
            }

            ul {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                li {
                    flex-grow: 1;
                    border: none;

                    &:first-of-type {
                        border-right: 1px solid rgba($white, 0.6);
                    }

                    a {
                        font-size: 14px;
                        padding: 0 5px;
                        text-align: center;
                    }
                }
            }
        }
    }

    &.open {
        pointer-events: all;
        opacity: 1;
        >a {
            width: 90px;
            color: $bahama-blue;
            background-color: $mantis;
        }
    }

}

nav {
    position: absolute;
    top: 1px;
    right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    background-color: inherit;
    max-height: calc(100vh - 54px);
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    >a {
        display: inline-block;
        line-height: 0;

        &+a {
            margin-left: 5px;
        }
    }
}
