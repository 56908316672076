main > unique-selling-points {
    display: block;
    text-align: center;
    padding: 0;

    img {
        margin-bottom: 2em;
    }
    h1 {
        color: $blue;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 30px;
    }
    h2 {
        font-weight: 700;
        font-size: 19px;
        margin-bottom: $gap4;
    }

    usp-blocks {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        > div {
            padding: 20px 0;

            svg {
                width: 60px;
                height: 60px;
                fill: $blue;
            }

            h4 {
                margin-top: 20px;
                text-transform: uppercase;
            }
        }
    }
}
@media(min-width: $medium) {
    main > unique-selling-points {
        // margin-top: $gap3;

        usp-blocks {
            flex-direction: row;

            > div {
                min-height: 250px;
                width: 50%;
                padding: 20px;
            }
        }
    }
}
@media(min-width: $large) {
    main > unique-selling-points {
        usp-blocks {
            > div {
                width: 33%;
            }
        }
    }
}
