////////////////////////////////////////////////////////////////////////////////
// General Settings
////////////////////////////////////////////////////////////////////////////////

// =============================================================================
// Colours
// =============================================================================


$white   : #FFFFFF;
$grey    : #f6f6f6;
$grey1   : #a5a5a5;
$grey2   : #b1b1b1;
$grey3   : #919191;
$dusty-gray : #969696;
$thunder : #262626;
$mine-shaft: #343434;
$grey-200   : #202020;

$mantis     : #7fcb54;
$bahama-blue: #005698;
$web-orange : #f8a700;
$zumthor    : #ebf5ff;


$green   : #96c11f;
$green3  : #f4f8e9;
$blue1   : #29235c;
$blue2   : #00a8e7;
$blue3   : #e3effb;

// New colors
$black   : #1d1d1b;
$orange  : #f3b019;
$purple  : #6f2284;
$blue    : #0081ba;
// - - -

$yellow  : #ffce00;
$sunrise : #f3c354;

$trustpilot-green	: #00b67a;

$selection  : $bahama-blue;
$text-colour : $blue1;

$facbeook    : #3b5998;
$twitter     : #00aced;
$linkedin    : #0e76a8;

// =============================================================================
// Units
// =============================================================================

$border-radius : 6;
$gaps          : 16;
$font-size     : 16;
$spacing       : 3;
$gutters       : $gaps*2;
$sidebar-width : 300px;
$letterspace   : 3px;
$sticky-height : 54px;

// =============================================================================
// Breakpoints
// =============================================================================

// $min           : 320px;
// $small         : 480px;
// $medium        : 800px;
// $large         : 970px;
// $max           : 1200px;

// =============================================================================
// Fonts
// =============================================================================
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/montserrat-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/montserrat-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v13-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('/assets/fonts/montserrat-v13-latin-500.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/montserrat-v13-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/montserrat-v13-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/montserrat-v13-latin-500.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/montserrat-v13-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/montserrat-v13-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('/assets/fonts/montserrat-v13-latin-600.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/montserrat-v13-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/montserrat-v13-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/montserrat-v13-latin-600.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/montserrat-v13-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/montserrat-v13-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('/assets/fonts/montserrat-v13-latin-700.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/montserrat-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/montserrat-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/montserrat-v13-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/montserrat-v13-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/montserrat-v13-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: "Mandator";
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: url('/assets/fonts/mandator-webfont.woff2') format('woff2'),  /* Super Modern Browsers */
        url('/assets/fonts/mandator-webfont.woff') format('woff'), /* Modern Browsers */
        url('/assets/fonts/mandator-webfont.ttf') format('truetype'); /* Safari, Android, iOS */
}
.regular {
    @extend %font-smooth;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}
.medium, .default {
    @extend %font-smooth;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}
.semi-bold {
    @extend %font-smooth;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}
.bold  {
    @extend %font-smooth;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}
.mandator {
    @extend %font-smooth;
    font-family: "Mandator", Helvetica, sans-serif;
    font-weight: 500;
}
.monospace {
    font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
    font-weight: 400;
}


// =============================================================================
// Miscellaneous
// =============================================================================

$show-breakpoints : true;
// $support-css-variable-alpha-colours : true;

$primary: $mantis;
$secondary: $bahama-blue;

// Custom Properties / CSS Variables
// These will be assigned to the html root.
// Colours should always be in hex format where possible.
$custom-properties : (
  primary   : if(variable-exists(primary), $primary, $blue),
  secondary : if(variable-exists(secondary), $secondary, $pink),
	sidebar-width: $sidebar-width
);

// =============================================================================
// Doggistyle
// =============================================================================

@include doggistyle();
