body.landing-pages  {
    main {
        .lp-section {
            padding: $gap2 $gap;

            &:first-of-type {
                h2 {
                    color: $blue2;
                }
                p {
                    text-align: center;
                    &:last-of-type {
                        font-weight: 600;
                    }
                }
            }
            // "odd" but not first
            &:nth-of-type(2n+3) {
                background-color: $green3;

                svg {
                    fill: $mantis;
                }
            }
            &:nth-child(even) {
                background-color: $blue3;

                svg {
                    fill: $blue2;
                }
            }
            &:nth-of-type(n+3) {
                margin-top: $gap;
            }

            header {
                text-align: center;

                svg {
                    width: 80px;
                    height: 80px;
                    margin-bottom: $gap;

                    & + h2 {
                        font-size: 18px;
                    }
                }
                h1, h2 {
                    line-height: 1.4;
                    margin-bottom: $gap;
                }
                h1 {
                    font-weight: 100;
                    text-transform: uppercase;
                }
            }
            p {
                text-align: justify;
                margin-bottom: 1em;
                line-height: 1.4;

                &:first-of-type {
                    font-weight: 600;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .cta-button {
            padding: $gap $gap 0;
            text-align: center;
        }

        // Responsive styles
        @media(min-width: $small-medium) {
            .lp-section {
                padding: $gap2;
            }
            .cta-button {
                padding: $gap2 $gap2 0;
            }
        }
        @media(min-width: $medium) {
            .lp-section {
                padding: $gap4;

                &:nth-of-type(n+3) {
                    margin-top: $gap2;
                }
            }
            .cta-button {
                padding: $gap4 $gap4 0;

                a {
                    font-size: 24px;
                }
            }
        }
    }
}
