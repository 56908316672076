section#how-it-works {
    @extend .content-block;
    padding-top: $gap2;
    padding-bottom: $gap2;
    margin-bottom: 0;

    h2 {
        text-transform: initial;
        color: $bahama-blue;
        font-weight: 400;
        font-size: 26px;
        margin-bottom: $gap0;
        text-align: center;
    }

    p {
        color: $bahama-blue;
        text-align: center;
    }

    ul {
        text-align: inherit;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        li {
            position: relative;
            margin: 0.5em 0 0.5em 1em;

            &:before {
                content: '\2022';
                color: $mantis;
                position: absolute;
                top: -0.4em;
                font-size: 2em;
                left: -0.5em;
            }
        }
    }

    // Overrides
    .col-layout {
        width: 100%;
        counter-reset: element-counter;
        padding-top: 0;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            display: block;
            width: 1px;
            top: $gap3;
            left: $gap2;
            bottom: 10%;
            background-color: $bahama-blue;
        }

        &>div {
            width: 100%;
            padding: 0 24px;
            padding-top: $gap3;
            position: relative;
            counter-increment: element-counter;

            &:before {
                content: "";
                display: block;
                position: absolute;
                height: 64px;
                width: 64px;
                left: 0;
                border-radius: 50%;
                background-color: $bahama-blue;
            }
            &:after {
                content: counter(element-counter);
                display: block;
                position: absolute;
                height: 64px;
                width: 64px;
                left: 0;
                top: $gap3;
                font-size: 32px;
                line-height: 2;
                color: white;
                text-align: center;
            }

            &:nth-of-type(2n) {
                &:before {
                    background-color: $web-orange;
                }
            }
            &:nth-of-type(3n) {
                &:before {
                    background-color: $mantis;
                }
            }
            &:last-of-type {
                border-top: 0px solid transparent;
            }

            p, ul {
                padding-left: 60px;
                text-align: center;

                span {
                    display: inline-block;
                    white-space: nowrap;
                }
            }
        }
    }

    svg,
    img {
        max-width: 500px;
        margin: 0 auto 32px;
        display: block;
        padding-top: 32px;
    }

    @media (min-width: $medium) {
        padding: 0 $gap2 $gap2;

        .col-layout {
            flex-wrap: nowrap !important;
            padding-top: $gap4;

            &:before {
                height: 1px;
                width: 80%;
                top: $gap4;
                left: 10%;
                right: 10%;
            }
            &>div {
                &:before,
                &:after {
                    top: -32px;
                    left: 50%;
                    transform: translateX(-50%);
                }
                p {
                    padding-left: 0;
                }
            }
        }
    }
}
