body#complete {

    main {
        padding-top: 0;
    }

    #content {
        display: flex;
        flex-direction: column;
        padding: $gap5 $gap2;
        text-align: center;

        h2 {
            font-size: 48px;
            font-weight: 600;
        }

        h4 {
            font-size: 36px;
            margin-bottom: 40px;
            letter-spacing: 3px;
        }

        h5 {
            font-size: 20px;
            color: $bahama-blue;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: 600;
        }

        p {
            font-size: 20px;
            line-height: 1.4;
            display: block;
            margin: 0px auto;
        }

        b,
        a {
            color: $bahama-blue;
        }

        a {
            font-size: 1.4em;
        }

        > div {
            img {
                margin: $gap2 auto;
            }
        }

        .app-complete-list {
            padding: $gap 0;
            text-align: left;
            width: auto;
            margin-left: auto;
            margin-right: auto;

            ul {
                margin: 0 32px 32px 32px;
                list-style: disc;

                li {
                    line-height: 30px;
                }
            }
        }
    }

}
