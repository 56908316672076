body#reviews {
    .content-header {
        background-color: $blue1;
        position: relative;

        &:after {
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -20px;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 25px 20px 0 20px;
            border-color: $blue1 transparent transparent transparent;
        }

        .content-wrap {
            max-width: $max;
            margin: 0 auto;
            padding: $gap4 $gap2;
            text-align: center;
            color: $white;
            line-height: 1.4;
            max-width: 800px;
            user-select: none;

            h1 {
                color: inherit;
            }
        }
    }

    #content {
        background-color: $white;
    }
}
