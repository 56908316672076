#cta-panes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $gap2;
    background-repeat: no-repeat;
    background-size: 80% auto;
    background-position: center top;

    article {
        background-color: $zumthor;
        padding: $gap2;
        color: $blue1;
        width: 100%;

        >svg,
        >img {
            max-height: 100px;
            max-width: 100%;
        }

        h3 {
            font-weight: 400;
            font-size: 28px;
            text-transform: initial;
            margin-top: 1em;
        }

        p{
            margin-bottom: 16px;
        }
    
        contact-details {
            padding: 0;
            text-align: left;
            
            svg {
                margin-left: 0;
            }
        }
    }

    @include break($medium, '>') {
        article {
            width: calc(50% - #{$gap});
            margin: 0 auto;
        }
    }
}

#home{
    #cta-panes{    
        article {
            contact-details {
                padding: 32px 0;
                margin-bottom: 0;
            }
        }
    }
}
