trustpilot-badge {
    .stars {
        position: relative;
        width: 155px;
        margin: 0 auto 12px;
        padding-bottom: 28px;

        .star:nth-of-type(-n+5) .star__canvas,
        .star:nth-of-type(-n+5) .star__canvas--half {
            fill: #00b67a;
        }

        svg {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }
    }

    .business-info {
        font-size: 13px;
        line-height: 1.2307;
        margin-bottom: 1.0769em;
        font-weight: 300;

        strong {
            font-weight: 500;
            border-bottom: 1px solid rgba(25, 25, 25, 0.6);
        }
    }

    .logo {
        position: relative;
        width: 95px;
        margin: 0 auto;
        padding-bottom: 25px;

        a {
            display: block;
        }

        svg {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }
    }
    // Page/parent component specific styling - `_components/home-masthead`
    #content & {
        display: flex;
        flex-direction: row-reverse;
        align-content: center;
        justify-content: space-between;
        max-width: 100%;

        > div {
            flex: 1;
            margin: 0;
        }

        .stars {
            margin: 0 0 0 20px;
        }

        .logo {
            width: 120px;
            padding-bottom: 35px;
        }
    }
}

// "Our Customers Love Us!" Section
trust-pilot-reviews {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: $gap4 0 $gap2;
    border-radius: 8px;

    h4 {
        text-transform: uppercase;
    }

    .introduction {
        p {
            text-transform: uppercase;
            margin-bottom: $gap2;
        }
    }

    .review-page-cta {
        color: $black;

        h4 {
            font-size: 48px;
            color: $black;
            margin-bottom: $gap2;
        }
        trustpilot-badge {
            display: block;
            position: relative;
            overflow: hidden;

            .logo {
                width: 200px;
                padding-bottom: 50px;
                margin-bottom: $gap2;
            }
            .stars {
                width: 200px;
                padding-bottom: 40px;
                margin-bottom: $gap;
            }
        }
        p {
            margin-bottom: $gap;

            &:last-of-type {
                margin-top: $gap4;
            }
        }
        strong {
            font-size: 1.1em;
        }
        a:not(.button) {
            color: $mantis;

            &:hover, &:focus, &:active {
                color: darken($mantis, 10%);
            }
        }
    }

    @media(min-width: $small-medium) {
       .review-page-cta {
           trustpilot-badge {
               overflow: visible;

               &:before,
               &:after {
                   position: absolute;
                   content: '';
                   width: 150px;
                   height: 175px;
                   background-repeat: no-repeat;
                   background-size: contain;
                   background-position: center center;
                   top: 50%;
                   transform: translateY(-50%);
               }
               &:before {
                   background-image: url('/assets/images/star-cluster-left.svg');;
                   right: 95%;
               }
               &:after {
                   background-image: url('/assets/images/star-cluster-right.svg');;
                   left: 95%;
               }
           }
       }
   }
}

#trustpilot-carousel {
    padding: 25px 0;
    font-family: "Segoe UI","Helvetica Neue","Helvetica","Arial","sans-serif";
    font-size: 12px;
    color: $thunder;
    max-width: none;
    min-width: 300px;
    display: flex;
    margin-bottom: 32px;
    flex-wrap: wrap;

    h3 {
        color: $bahama-blue;
        font-size: 26px;
        font-weight: 400;
        margin-bottom: 32px;
        width: 100%;
        text-align: center;
    }
    .cta-container {
        margin-top: 32px;
        width: 100%;
        text-align: center;
        color: $thunder;
    }

    // Slick slider styles
    .slick-prev {
        left: 10px;
    }

    .slick-next {
        right: 10px;
    }

    .slick-arrow {
        border-radius: 50%;
        background-color: $black;

        &:before {
            line-height: 1.1;
        }
    }
    // Generic fixes
    a {
        // color: inherit;
        text-decoration: none;
        color: $mine-shaft;

    }

    .wrapper-left {
        width: 100%;
        text-align: center;
        .score{
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 0.5em;
        }
        .rating {
            font-size: 14px;
            margin-bottom: 0.5em;
            font-weight: 500;
        }
    }

    .wrapper-right {
        width: calc(100% - 200px);
        padding: 0 40px;
        display: block;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.4s ease;

        &.slick-initialized {
            visibility: visible;
            opacity: 1;
        }

        .stars {
            [class^="stars--"] {
                position: relative;
                height: 0;
                width: 100%;
                padding: 0;
                padding-bottom: 20px;
            }
            svg {
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
            }
        }

        .review {
            position: relative;
            height: 130px;
            margin-right: 15px;
            padding-left: 20px;
            outline: none;

            .stars {
                position: relative;
                width: 110px;
                margin-bottom: 12px;

                svg {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                }
            }

            .relative-date {
                color: $thunder;
                position: absolute;
                right: 0;
                top: 4px;
            }

            .header {
                font-size: 14px;
                font-weight: 700;
                height: 1.1428em;
                margin-bottom: 0.5714em;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;

                a {
                    &:active,
                    &:focus,
                    &:hover {
                        outline: none;
                        text-decoration: underline;
                    }
                }
            }

            .text {
                font-size: 13px;
                line-height: 1.2307;
                word-wrap: break-word;
            }

            .name {
                position: absolute;
                bottom: 0;
                height: 1.1428em;
                left: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: calc(100% - 20px);
                color: $dusty-gray;
            }
        }
    }
}
// Responsive styles
@media(max-width: 767px) {
    #trustpilot-carousel {
        .wrapper-right {
            margin-top: 32px;
            width: 100%;
            .review{
                padding-left: 0;
            }
        }
    }
}
@media(min-width: 768px) {
    #trustpilot-carousel {
        .wrapper-left {
            width: 200px;
        }
    }
}
