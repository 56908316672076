#player {
    display: none;

    &.active {
        display: block;
        position: fixed;
        top: 15vh !important;
        left: 15vw;
        height: 70vh;
        width: 70vw;
        z-index: 99;

        &::before {
            content: "";
        }
    }
}

#video-flyout {
    background-color: #29235c;
    padding: 12px 32px 12px 12px;
    position: fixed;
    z-index: 7;
    display: none;

    &.ready {
        display: block;
    }

    #minimise-btn {
        display: block !important;
        position: absolute;
        top: 0;
        right: 0;
        padding: 16px;

        &:before {
            content: "";
            color: white;
            position: absolute;
            top: 15px;
            right: 15px;
            padding: 5px;
            border-right: 2px solid white;
            border-bottom: 2px solid white;
            transform: rotate(-45deg);
        }
    }

    &:after,
    &:before {
        content: "";
        position: absolute;
        pointer-events: none;
        top: 50%;
        left: 50%;
        transform: translate(-50% -50%);
    }

    &:after {
        width: 0;
        height: 0;
        border: 16px solid transparent;
        border-left: 20px solid #fff;
        margin-top: -19px;
        margin-left: -10px;
        -webkit-filter: drop-shadow(0px 0px 2px rgba(0,0,0,0.25));
    }

    &:before {
        margin-top: -34px;
        margin-left: -34px;
        width: 60px;
        height: 60px;
        border: 3px solid #fff;
        border-radius: 50%;
        box-shadow: 0 0 4px rgba(black, 0.18);
        background: rgba(black, 0.42);
    }

    &:hover {
        cursor: pointer;

        &:before {
            background: rgba(black, 0.7);
        }
    }

    img {
        height: 140px;
    }
}

#video-overlay {
    opacity: 0;

    &.active {
        position: absolute;
        top: 0;
        left: -100%;
        width: 300%;
        height: 100vh;
        background-color: #29235c;
        opacity: 0.7;
        transition: opacity 0.3s ease-in;
        z-index: 98;
    }
}
@media(min-width: 841px) {
    #video-flyout {
        top: 60vh;
        transform: translateX(0%);
        right: 0;
        transition: transform 0.3s ease-out;

        &.minimised {
            transform: translateX(calc(100% - 30px));
            padding: 12px 10px 12px 30px;

            img {
                right: 0;
            }

            #minimise-btn {
                left: 1px;
                right: auto;
                padding: 16px;

                &:before {
                    top: 15px;
                    left: 10px;
                    right: auto;
                    position: absolute;
                    transform: rotate(-225deg);
                }
            }
        }
    }
}
@media(max-width: 840px) {
    #video-flyout {
        position: relative;
        width: 330px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        padding: 0;
        margin-top: 32px;

        img {
            width: 100%;
            height: auto;
        }
    }

    #player.active {
        width: 90%;
        left: 5%;
    }
}
