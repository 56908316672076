@keyframes flickerAnimation {
  0%   { opacity: 0; }
  10%   { opacity: 1; }
  20%   { opacity: 0; }
  30%   { opacity: 0; }
  40%   { opacity: 0; }
  50%  { opacity: 0; }
  60%  { opacity: 0; }
  70%  { opacity: 0; }
  80%  { opacity: 0; }
  90%  { opacity: 0; }
  100% { opacity: 0; }
}

.promo {
    width: 100%;
    text-align: center;
    margin-top:150px;

    header {
        position: relative;
    }
    img {
        position: absolute;
        z-index: 0;
        top:-150px;
        left:50%;
        transform: translateX(-50%);
        max-width: 750px;
    }
    h2, h3 {;
        position: relative;
        text-transform: uppercase;
        color: $black;
        vertical-align: middle;
        line-height: 1.2;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 19px;
        letter-spacing: 0px;
        margin-bottom:1em;
    }

    p {
        margin-bottom: 0.75em;
        &:last-of-type {
            margin-bottom:0;
        }
    }
    .usp-items {
        text-transform: uppercase;
        font-size: 0.8em;
        position: relative;
        height: 2.5333em;

        span {
            display: block;
            width: 100%;
            position: absolute;
            top:0;
            left: 50%;
            transform: translateX(-50%);
            opacity: 0;
            transition: opacity ease-in-out 300ms;

            // NOTE: Hard-coded for number of items
            @for $i from 1 through 5 {
                animation: flickerAnimation 20s infinite;
                &:nth-child(#{$i}n) {
                    animation-delay: #{($i * 4) - 4}s;
                }
            }
        }
    }
    small {
        font-size: 60%;
    }

    @include break($small-medium, '>') {
        .usp-items {
            height: auto;

            span {
                display: inline-block;
                opacity: 1;
                width: auto;
                animation: none;
                position: relative;
                top:auto;
                left: auto;
                transform: none;

                & + span {
                    position: relative;
                    margin-left: 2em;

                    &:before {
                        content: '';
                        background-color: $mantis;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left:-1.25em;
                        width:0.5em;
                        height:0.5em;
                        display: block;
                        border-radius: 1em;
                    }
                }
            }
        }
    }

    @include break($large, '>') {
        img {
            top:-70px;
        }
        h2, h3 {
            margin:0;
            white-space: nowrap;
        }
    }
}

// Specific styles for each promo
.promo {
    &#summer-2019 {
        @include break($small-medium, '>') {
            .usp-items span + span:before {
                background-color: $sunrise;
            }
        }
        @include break($large, '>') {
            margin-top:50px;

            header {
                margin-bottom:30px;
            }
            h2, h3 {
                display: inline-block;
                width: 50%;
            }
            h2 {
                text-align: right;
                padding-right: 75px;
            }
            h3 {
                text-align: left;
                padding-left: 75px;
            }
        }
    }
    &#logbook-loan-alternative {
        margin-top:75px;

        header {
            margin-bottom:15px;
        }
        img {
            top: -100px;
        }
        h2 {
            margin:0;
        }

        @include break($small-medium, '>') {
            .usp-items span + span:before {
                background-color: $mantis;
            }
        }
        @include break($large, '>') {
            margin-top:70px;

            img {
                top:-90px;
                // NOTE annoying issue with image not centering perfectly
                transform: translateX(calc(-50% - 15px));
            }
            h2 {
                span {
                    & + span {
                        padding-left: 50px;
                    }
                }
            }
        }
    }
    &#cash-in-your-bank {
        margin-top:70px;

        h2 {
            font-size: 28px;
            margin-bottom: 0.25em;
        }
        h3 {
            color: $blue1;
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 1em;
        }
        img {
            top: -80px;
        }
    }

    &#standard-promo {
        margin-top:0px;

        h2 {
            font-size: 32px;
            margin-bottom: 0.25em;
            font-weight: bold;
        }
        h3 {
            color: $blue;
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 1em;
        }
        p.usp-items {
            color: $grey3;
            span + span:before {
                background-color: $orange;
            }
        }
        img {
            top: -80px;
        }
        svg {
            width: 60px;
        }
    }
}

.badge-promo {
    width:100%;
    position: relative;
    z-index:20;

    img {
        position: absolute;
        right: 0;
        top: 0;
        width: 100px;
        height: 100px;
        transform: translate(15%, -30%);
    }
}

// Specific styles for each badge
.badge-promo {
    &#lbc-radio-2019 {
        & + .promo {
            margin-top: 100px;
        }

        @include break($min, '>') {
            img {
                width: 125px;
                height: 125px;
            }
            & + .promo {
                margin-top: 120px;
            }
        }
        @include break($small, '>') {
            img {
                transform: translate(0%, -20%);
            }
        }
        @include break($small-medium, '>') {
            img {
                width: 200px;
                height: 200px;
                transform: translate(12%, -60%);
            }
            & + .promo {
                margin-top: 70px;
            }
        }
        @include break($medium, '>') {
            img {
                transform: translate(55%, -25%);
            }
            & + .promo {
                margin-top: 140px;
            }
        }
        @include break(1024px, '>') {
            & + .promo {
                margin-top: 70px;
            }
        }
        @include break($large, '>') {
            img {
                width: 250px;
                height: 250px;
                transform: translate(25%, -35%);
            }
        }
    }
}
