////////////////////////////////////////////////////////////////////////////////
// Footer
////////////////////////////////////////////////////////////////////////////////
.footer-icons {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: center;
    padding: $gap2;
    text-align: center;

    > img {
        display: block;
        margin: $gap2 auto 0;
    }
    img {
        max-width: 100%;
    }

    @media (min-width: $small-medium) {
        flex-wrap: nowrap;
        flex-direction: row;

        > img {
            margin: 0 0 0 $gap2;
        }
    }
    @media (min-width: $medium) {
        > img {
            margin: 0 0 0 $gap4;
        }
    }
}


fca-footer {
    > img {
        max-height: 70px;
    }
    p {
        margin-bottom: $gap;

        &:last-of-type {
            margin-bottom: 0;
        }
        a {
            margin: 0 5px;
        }
    }
}

.footer-wrapping {
    footer {
        background: $bahama-blue;
        padding: 40px 20px;

        .pyc-logo {
            width: 100%;
            max-width: 340px;
            margin-bottom: $gap;
        }

        p {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: $gap;
            color: $white;
        }

        .footer-wrap {
            max-width: $max;

            > div {
                text-align: center;

                &:first-of-type {
                    svg {
                        max-width: 460px;
                        margin-bottom: 20px;
                    }

                    a {
                        color: $web-orange;
                    }
                }

                &:last-of-type {
                    ul {
                        break-inside: avoid;
                        margin-bottom: 25px;

                        li {
                            color: $white;
                            line-height: 23px;
                            padding: 3px 0;
                            font-size: 14px;

                            &:first-of-type {
                                color: $web-orange;
                                font-weight: 700;
                            }
                        }
                    }

                    a {
                        color: $white;

                        &:hover, &:active, &:focus {
                            color: $web-orange;
                        }
                    }
                }
            }
        }

        svg {
            &:first-of-type {
                display: block;

                > svg {
                    width: 250px;
                }
            }

            &:last-of-type {
                display: none;
            }
        }
        @media(min-width: $small) {
            .footer-wrap > div {
                text-align: left;

                &:first-of-type {
                    padding-bottom: 40px;
                }

                &:last-of-type {
                    padding: 0 70px 0 0;
                    column-count: 2;
                }
            }
        }
        @media(min-width: $medium) {
            .footer-wrap > div {
                &:last-of-type {
                    column-count: 3;
                }

                svg {
                    &:first-of-type {
                        display: none;
                    }

                    &:last-of-type {
                        display: block;

                        > svg {
                            width: 445px;
                            margin-top: 18px;
                        }
                    }
                }
            }
        }
        @media(min-width: $large) {
            .footer-wrap {
                display: flex;
                flex-direction: row;
                padding: 40px;
                margin: 0 auto;

                > div {
                    padding: 0 20px;

                    &:first-of-type {
                        width: 45%;
                        padding-bottom: 0;
                        border-right: 1px solid rgba($white, 0.3);
                    }

                    &:last-of-type {
                        padding: 0 70px 0 40px;
                        width: 55%;
                        column-count: 3;
                    }
                }
            }
        }
    }
}
