#contact-form-blank { display: none !important }

introducers-form {
  display: block;
  margin-top: 5rem;

  form {

    [disabled] {
      opacity: 0.3;
      pointer-events: none;
    }

    h3 { margin-bottom: 3.2rem }

    display: grid;
    grid-gap: 6rem;

    .select.inline {
      display: grid;
      gap: 1rem 0;
      width: fit-content;
      margin-bottom: 3.2rem;
    }

    input-fields {
      --gap-x: 5rem;
      --gap-y: 1rem;
      --container-width: calc((100% - var(--gap-x)) / 2);

      display: flex;
      flex-wrap: wrap;
      gap: var(--gap-y) var(--gap-x);

      > div {
        min-width: var(--container-width);
        flex: 1;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;

        input:not([type="date"]), .select-wrapper {
          width: 100%;
        }

        input {        
          &[type="checkbox"] {
            width: fit-content;
          }
        }

        &.date, &.select {
          display: grid;
          grid-auto-flow: column;
          grid-gap: 2rem;
          align-items: center;

          label {
            width: fit-content;
          }
        }
      }

      @include break-in('small-medium') {
        --container-width: 100%;

        > div.date, div.select {
          grid-auto-flow: row;

          label, .select-wrapper { order: 1}
          input { order: 2}
        }
      }
    }

  }
}

.introducers-thank-you {
  display: grid;
  grid-gap: 4rem 0;
  text-align: center;

  .button {
    width: fit-content;
    justify-self: center;
  }
}