$loyc-dark: #0c275f;
$loyc-light: #e6e9ef;
$varooma-dark: #e8b263;
$varooma-light: #fbf3e5;
$ccp-dark: #4ea7e6;
$ccp-light: #e8f3fc;

#loyc-vs-competitors {
    @extend .content-block;
    padding: $gap;

    header {
        h1 {
            font-size: 24px;
            font-weight: 100;
            color: $blue2;
            margin: 0;

            svg {
                height: 1em;
                vertical-align: middle;
                max-width: 100%;
            }
        }
    }

    table {
        img {
            max-width: 300px;
        }
        td,
        th {
            width: 25%;

            &:nth-child(2) {
                background-color: $loyc-light;
            }
            &:nth-child(3) {
                background-color: $varooma-light;
            }
            &:nth-child(4) {
                background-color: $ccp-light;
            }
        }
        svg {
            max-width: 100%;
        }

        tbody {
            background-color: $grey;

            th {
                text-align: right;
            }
            td {
                p {
                    margin-bottom: 0.5em;

                    & + p {
                        font-size: 0.85em;
                    }
                }
            }
            tr:first-child {
                td {
                    color: $white;

                    &:nth-child(2) {
                        background-color: $loyc-dark;
                    }
                    &:nth-child(3) {
                        background-color: $varooma-dark;
                    }
                    &:nth-child(4) {
                        background-color: $ccp-dark;
                    }
                }
            }
        }
    }

    // Overrides
    .col-layout {
        > div {
            margin-top: $gap;

            &:last-of-type p {
                text-align: center;
            }
        }
    }

    @media (min-width: $medium) {
        padding: $gap4;

        header {
            h1 {
                font-size: 30px;
            }
        }
        .col-layout {
            > div {
                margin-top: $gap2;
            }
        }
    }
}
