body#switch-and-save{

  #content {
    text-align: center;
    h1 {
      text-transform: uppercase;
      color: $bahama-blue;
      letter-spacing: normal;
    }
    > svg{

      max-width:300px;
      margin-bottom: $gap2;
    }
  }

}
