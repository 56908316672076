loan-features{
  display: flex;
  flex-direction: column;
  text-align:center;
  background: $grey;
  padding:$gap2;

  > div{
    padding:15px 15px 35px 15px;
    border-bottom:2px solid $white;
    &:last-of-type{
      border-bottom:0px;
    }
  }

  svg{
    width:60px;
    height:60px;
    fill:$orange;
    margin-bottom: $gap1;
  }
}

@media(min-width: $medium ){

}

@media(min-width: $large ){

  loan-features{
    flex-direction: row;
    > div{
      border-bottom:0px;
      border-right:2px solid $white;
      width:33%;

      &:last-of-type{
        border-right:0px;
      }
    }
  }

}
