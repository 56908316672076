#research-statement {
    @extend .content-block;
    background-color: $grey;
    padding: $gap;

    svg {
        width: 80px;
        height: 80px;
        fill: $blue;
        margin-bottom: $gap2;
    }

    // Overrides
    .w100  {
        text-align: center;

        p {
            text-align: inherit;
        }
    }

    @media (min-width: $medium) {
        padding: $gap7 $gap9;
        
    }
}
