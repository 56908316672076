////////////////////////////////////////////////////////////////////////////////
// Main
////////////////////////////////////////////////////////////////////////////////

main {

    position: relative;
    flex-grow: 1;
    max-width: $max;
    display: block;
    margin: 0 auto;
    padding-left: $gap1;
    padding-right: $gap1;
    padding-top: $gap1;

    #content {
        overflow: hidden;
    }

    >* {
        position: relative;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: var(secondary)
    }

    p,
    li {
        font-size: $font-size + px;
        font-weight: 400;
        line-height: 1.6;
        margin-bottom: revert;
    }

    a {
        color: var(secondary);

        &:hover {
            color: var(primary);
        }
    }

    @include break($medium, '>') {
        padding-left: $gap2;
        padding-right: $gap2;
        padding-top: $gap2;
    }

    @include break($medium, '<') {

        &.footer-wrapping {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

}
