$border-radius: 4px;
$item-indent: 20px;
$article-indent: 40px;

#vehicle-equity-release {
    #content {
        padding: 20px;

        h1, h2 {
            line-height: 1.2;
        }
        h1 {
            text-transform: uppercase;
        }
        h2 {
            margin-top:0;
            font-weight: 400;
        }

        .action-bar {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 20px 0 60px;

            &.open {
                > div {
                    background-color: $mantis;

                    &:first-of-type:after {
                        border-right-color: $white;
                        border-top-color: $white;
                    }
                    &:last-of-type {
                        pointer-events: none;
                        &:before,
                        & > * {
                            opacity: 0;
                        }
                    }
                }
                button,
                input {
                    color: $white;
                }
                nav {
                    top: calc(50%);
                    opacity: 1;
                    pointer-events: auto;
                }
            }

            > div {
                background-color: $white;
                width: 100%;
                position: relative;
                z-index: 2;
                font-size: 18px;
                transition: background-color .2s ease-in-out;

                &:first-of-type {
                    border-top-left-radius: $border-radius;
                    border-top-right-radius: $border-radius;
                    z-index: 3;

                    &:after {
                        pointer-events: none;
                        transform: translateY(-50%) rotate(135deg);
                        height: 12px;
                        width: 12px;
                        border-right: 2px solid $mantis;
                        border-top: 2px solid $mantis;
                        transform-origin: center;
                        content: '';
                        right: 30px;
                        top: 50%;
                        position: absolute;
                        opacity: 1;
                        transition: transform .2s ease-in-out, opacity .2s ease-in-out, border-color .2s ease-in-out;
                    }
                }
                &:last-of-type {
                    border-bottom-left-radius: $border-radius;
                    border-bottom-right-radius: $border-radius;
                    z-index: 1;

                    & > * {
                        opacity: 1;
                        transition: opacity .2s ease-in-out;
                    }
                }
                .mag-icon {
                    height: 1em;
                    width: 1em;
                    border-radius: 50%;
                    border: 2px solid $mantis;
                    display: block;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right:28px;

                    &:after {
                        content: '';
                        height: 2px;
                        width: 0.5em;
                        background-color: $mantis;
                        position: absolute;
                        top: 0.8em;
                        left: 0.6em;
                        transform: rotate(45deg);
                    }
                }
                button,
                input {
                    text-transform: uppercase;
                    font-weight: 500;
                    color: $grey1;
                    width:100%;
                    display:block;
                    padding: 0.5555em 1.6666em;
                    transition: color .2s ease-in-out;
                }
                button {
                    text-align: left;
                }
                input {
                    background-color: transparent;
                }
            }

            nav {
                opacity: 0;
                pointer-events: none;
                position: absolute;
                left: 0;
                top: calc(50% - 20px);
                z-index: 2;
                width: 100%;
                background-color: $blue2;
                box-shadow: 0px 20px 10px 0px rgba(0,0,0,0.2);
                border-bottom-left-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
                transition: opacity .2s ease-in-out, top .2s ease-in-out;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 30px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 15px 15px 0 15px;
                    border-color: $green transparent transparent transparent;
                }

                ul {
                    margin: 20px;
                    list-style: none;
                }
                li {
                    user-select: none;
                    break-inside: avoid-column;
                    font-weight: 500;
                    margin: 1.25em 0 1.25em 1em;
                    position: relative;

                    &:before {
                        transform: translateY(-50%) rotate(45deg);
                        height: 10px;
                        width: 10px;
                        border-right: 2px solid $white;
                        border-top: 2px solid $white;
                        transform-origin: center;
                        content: '';
                        left: -1.5em;
                        top: 50%;
                        position: absolute;
                    }
                    &:first-child {
                        margin-top: 0;
                    }
                }
                a {
                    color: $white;
                }
            }
        }

        article {
            background-color: $white;
            border-radius: $border-radius;

            & + article {
                margin-top: 60px;
            }

            &.closed {
                .content {
                    max-height: 0;
                    padding:0;
                    opacity: 0;
                    transition: max-height .2s ease-in-out .2s, padding .2s linear .2s, opacity .2s ease-in-out;
                }
                .title {
                    &:after {
                        transform: translateY(-70%) rotate(135deg);
                    }
                }
            }

            .content {
                overflow: hidden;
                max-height: 1000em;
                opacity: 1;
                padding: 20px;
                transition: max-height .2s ease-in-out, opacity .2s ease-in-out .2s;
            }
            .title {
                user-select: none;
                padding: calc(1em + 25px) 1em 1em;
                background-color: $green;
                color: $white;
                text-transform: uppercase;
                font-size: 18px;
                line-height: 1.2;
                letter-spacing: normal;
                position: relative;
                margin: 0;
                text-align: center;
                border-radius: $border-radius;
                cursor: pointer;

                svg {
                    position: absolute;
                    width: 50px;
                    fill: $white;
                    top: -35px;
                    left: calc(50% - 25px);
                    display: block;
                }

                &:before {
                    background-color: $blue2;
                    content: '';
                    display: block;
                    height: 70px;
                    width: 70px;
                    border-radius: 50%;
                    line-height: 0;
                    position: absolute;
                    top: -45px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            p {
                &:last-child {
                    margin-bottom:0;
                }
            }

            ul {
                list-style: none;
                margin:0 0 1em;

                &:last-child {
                    margin-bottom:0;
                }

                li {
                    margin: 0.5em 0 0.5em 1em;
                    position: relative;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:before {
                        content: '\2022';
                        color: $mantis;
                        position: absolute;
                        top: 0em;
                        left:-1em;
                    }
                }
            }

            // Article "module" styles
            .flex-pastel {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin: 2em 0;

                div {
                    padding: 20px;
                    width: 100%;

                    ul li:before {
                        color: $blue2;
                    }
                    &:first-of-type {
                        background-color: $blue3;
                        border-top-left-radius: $border-radius;
                        border-top-right-radius: $border-radius;


                        strong {
                            color: $blue2;
                        }
                    }
                    &:last-of-type {
                        background-color: $green3;
                        margin-top: 6px;
                        border-bottom-left-radius: $border-radius;
                        border-bottom-right-radius: $border-radius;

                        strong {
                            color: $mantis;
                        }
                    }
                }
            }
            .flex-border {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin: 2em 0;

                div {
                    width: 100%;

                    &:first-of-type {
                        padding-bottom: 20px;
                    }
                    &:last-of-type {
                        border-top: 3px solid $grey;
                        padding-top: 20px;
                    }
                }
            }

            .flex-li-cards {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                text-align: center;
                margin: 2em 0;

                > li {
                    background-color: $grey;
                    margin:0;
                    width: 100%;
                    padding: 20px;
                    border-radius: $border-radius;

                    &:before {
                        // UL overrides
                        content: '';
                        position: relative;
                        top: auto;
                        left: auto;

                        transform: rotate(135deg);
                        transform-origin: center;
                        height: 1em;
                        width: 1.75em;
                        border-right: 5px solid $mantis;
                        border-top: 5px solid $mantis;
                        display: block;
                        margin: 0 auto 2em;
                        text-align: center;
                    }
                    & + li {
                        margin-top: 20px;
                    }
                }
                h3 {
                    color: $blue1;
                    text-transform: uppercase;
                    letter-spacing: normal;
                    font-size: 20px;
                    line-height: 1.2;
                }
            }

            .dl-pastel {
                h3 {
                    text-align: center;
                    color: $blue1;
                    text-transform: uppercase;
                    letter-spacing: normal;
                    font-size: 20px;
                    line-height: 1.2;
                }
                dl {
                    user-select: none;
                    display: flex;;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin-bottom: 20px;
                    text-align: center;

                    &:last-of-type {
                        margin-bottom:0;
                    }

                    dt {
                        width: 100%;
                        padding: $item-indent 0 0;
                        color: $blue2;

                        &:nth-child(n + 3) {
                            margin-top: 2px;
                        }
                        &:first-of-type {
                            background-color: $blue3;
                            border-top-right-radius: $border-radius;
                            border-top-left-radius: $border-radius;
                        }
                        &:last-of-type {
                            background-color: $green3;
                        }
                    }
                    dd {
                        width: 100%;
                        padding: $item-indent;

                        &:first-of-type {
                            background-color: $blue3;
                        }
                        &:last-of-type {
                            background-color: $green3;
                            border-bottom-right-radius: $border-radius;
                            border-bottom-left-radius: $border-radius;
                        }
                    }
                }
            }

            .flex-ol {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                list-style: none;
                counter-reset: section;
                margin: 0;

                > li {
                    padding: 20px;
                    text-align: center;
                    margin: 0;
                    width: 100%;
                    background-color: $grey;
                    line-height: 1.6;
                    border-radius: $border-radius;

                    & + li {
                        margin-top: 1em;
                    }

                    &:before {
                        counter-increment: section;
                        content: counter(section);
                        background-color: $blue2;
                        font-size: 1.125em;
                        padding: 0.5em;
                        font-weight: 700;
                        display: block;
                        margin: 0 auto 1em;
                        height: 2em;
                        width: 2em;
                        text-align: center;
                        line-height: 1;
                        color: $white;
                        border-radius: 50%;
                    }

                    &:nth-child(3n),
                    &:last-child {
                        margin-right: 0;
                    }

                    &:last-child {
                        width: 100%;
                    }
                }
            }

        }
    }
}

@include break($large, '>') {
    #vehicle-equity-release {
        #content {
            // TODO use variable
            padding: 32px;

            h1 {
                font-size: 34px;
            }
            h2 {
                font-size: 26px;
            }

            .action-bar {
                margin: 40px 0;

                &.open {
                    > div {
                        &:first-of-type:after {
                            opacity: 0;
                        }
                    }
                    nav {
                        top: calc(100% - 3px);
                    }
                }
                > div {
                    width: 50%;

                    &:first-of-type {
                        z-index: 2;
                        border-top-right-radius: 0;
                        border-bottom-left-radius: $border-radius;
                    }
                    &:last-of-type {
                        z-index: 2;
                        border-bottom-left-radius: 0;
                        border-top-right-radius: $border-radius;

                        &:before {
                            content: '';
                            display: block;
                            width: 2px;
                            height: 1.2em;
                            background-color: $grey2;
                            position: absolute;
                            top: 0.4444em;
                            left:0;
                        }
                    }
                }
                nav {
                    top: calc(100% - 20px);
                    z-index: 1;

                    ul {
                        column-count: 2;
                        column-gap: 40px;
                        margin: 30px 60px;
                    }
                    li {
                        &:before {
                            left: -2.5em;
                        }
                    }
                }
            }

            article {

                & + article {
                    margin-top: 40px;
                }

                .content {
                    // TODO: resolve
                    padding: 60px $article-indent;
                }
                .title {
                    text-indent: 80px;
                    white-space: nowrap;
                    text-align: left;
                    padding: 1em;

                    &:before {
                        top:50%;
                        left: 15px;
                        transform: translateY(-50%);
                    }
                    &:after {
                        transform: translateY(-30%) rotate(-45deg);
                        height: 12px;
                        width: 12px;
                        border-right: 2px solid $white;
                        border-top: 2px solid $white;
                        transform-origin: center;
                        content: '';
                        right: $item-indent;
                        top: 50%;
                        position: absolute;
                        transition: transform .2s ease-in-out;
                    }

                    svg {
                        top: 50%;
                        left: 25px;
                        transform: translateY(-50%);
                    }
                }

                .flex-pastel {
                    div {
                        padding: $article-indent;

                        &:first-of-type {
                            width: calc(40% - 3px);
                            border-top-right-radius: 0;
                            border-bottom-left-radius: $border-radius;
                        }
                        &:last-of-type {
                            width: calc(60% - 3px);
                            margin-top: 0;
                            margin-left: 6px;
                            border-bottom-left-radius: 0;
                            border-top-right-radius: $border-radius;
                        }
                    }
                }
                .flex-border {
                    div {
                        width: 50%;

                        &:first-of-type {
                            padding-right: $article-indent;
                            padding-bottom: 0;
                        }
                        &:last-of-type {
                            border-top: 0;
                            border-left: 3px solid $grey;
                            padding-left: $article-indent;
                            padding-top: 0;
                        }
                    }
                }
                .flex-li-cards {
                    > li {
                        padding: $article-indent;
                        width: calc(50% - (#{$article-indent / 2}));

                        & + li {
                            margin-top: 0;
                        }

                        &:nth-child(even) {
                            margin-left:$article-indent;
                        }
                        &:nth-child(n + 3) {
                            margin-top: $article-indent;
                        }
                    }
                }
                .dl-pastel {
                    dl {
                        text-align: left;
                        margin-bottom: 3em;

                        dt {
                            width: calc(40% - 1.5px);
                            padding: $item-indent;

                            &:first-of-type {
                                border-top-right-radius: 0;
                            }
                            &:last-of-type {
                                border-bottom-left-radius: $border-radius;
                            }
                        }
                        dd {
                            width: calc(60% - 1.5px);
                            margin-left: 3px;

                            &:first-of-type {
                                border-top-right-radius: $border-radius;
                            }
                            &:nth-child(n + 3) {
                                margin-top: 2px;
                            }
                            &:last-of-type {
                                border-bottom-left-radius: 0;
                            }
                        }
                    }
                }
                .flex-ol {
                    > li {
                        padding: $article-indent;
                        width: calc(33.333% - 0.6666em);
                        margin-right: 1em;

                        & + li {
                            &:nth-child(-n + 3) {
                                margin-top:0;
                            }
                        }

                        &:nth-child(n + 4) {
                            margin-top: 1em;
                        }
                    }
                }
            }
        }
    }
}
