#home,
.landing-pages,
.locations {
    main {
        #content {
            // position: relative;
            // width: 100%;
            // min-height: 500px;
            // display: flex;
            // flex-direction: column;
            // justify-content: space-around;
            // align-items: center;
            // padding: 25px 10px;

            >header {
                text-align: center;

                a {
                    display: inline-block;
                }

                .brand-logo {
                    display: block;
                    width: 100%;
                }

                h1 {
                    font-size: 26px;
                    font-weight: 400;
                    line-height: 1.2;
                    color: $bahama-blue;
                    margin-top: $gap2;
                }

                h2 {
                    position: relative;
                    color: $bahama-blue;
                    font-size: 16px;
                    width: fit-content;
                    margin: $gap2 auto 0;

                    &:before,
                    &:after {
                        content: '';
                        display: block;
                        height: 2px;
                        width: 0.5em;
                        background-color: $bahama-blue;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &:before {
                        left: -1.5em;
                    }

                    &:after {
                        right: -1.5em;
                    }
                }

                img {
                    max-width: 100%;
                    // Offset form margins
                    margin-bottom: -$gap2;
                }
            }

            >svg {
                z-index: 2;

                &:first-of-type {
                    display: none;
                    width: 700px;
                }

                &:last-of-type {
                    display: block;
                    width: 300px;
                }
            }

            >p {
                font-size: 26px;
                text-transform: uppercase;
                letter-spacing: 3px;
                text-align: center;

                span {
                    font-weight: 600;
                }
            }

            input-fields {
                .check-and-submit {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;
                    width: 100%;
                    max-width: 100%;
                    flex: 1 1 100%;
                    padding: 0 $gap 0 5px;

                    >div {
                        flex: 1 1 100%;
                    }

                    @include break($medium, '>') {
                        flex-direction: row;
                    }

                }
            }

            input[type="submit"] {
                // margin-top: 30px;
                // font-size: 24px;
            }

            .years-svg {
                margin-top: $gap2;

                svg {

                    width: 400px;
                }
            }

            .badges {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;

                >* {
                    margin-top: $gap1;
                    max-width: 100%;
                }
            }

            contact-details {
                padding: 0;
                margin-bottom: 2em;
            }

            .social-icon {
                display: block;
                fill: $orange;

                svg {
                    width: 25px;
                    height: 25px;
                    transition: fill 300ms;
                }

                &:active,
                &:focus,
                &:hover {
                    svg {
                        fill: $blue1;
                    }
                }
            }

            nav.header-nav {
                position: absolute;
                top: 15px;
                right: 15px;
                display: flex;
                align-items: center;
                z-index: 2;

                >a {
                    display: inline-block;
                    line-height: 0;

                    &+a {
                        margin-left: 5px;
                    }
                }

                .menu-trigger {
                    width: 30px;
                    height: 30px;
                    margin-left: 20px;

                    svg {
                        width: 30px;
                        height: 30px;
                        fill: $blue;
                    }
                }
            }

            h3 {
                text-align: center;

                a {
                    color: $mantis;

                    &:hover {
                        color: $blue2;
                    }
                }
            }

            p {
                font-size: 15px;
            }

            @include break($medium, '>') {
                margin-top: $gap4;
                background-image: url('/assets/images/header-caravan.png?q=100&auto=format');
                background-repeat: no-repeat;
                background-size: auto 70%;
                background-position: right center;

                >header {
                    max-width: 65%;

                    .brand-logo {
                        height: 75px;
                        width: auto;
                    }

                    h1 {
                        font-size: 38px;
                    }

                    h2 {
                        font-size: 18px;
                    }

                    img {
                        display: none;
                    }
                }

                >form {
                    max-width: 65%;
                }
            }
        }

        .trustpilot-widget {
            padding: $gap2 0;
        }
    }

    // TODO: refactor this - we ALWAYS do MOBILE FIRST
    @include break($small, '<') {
        main {
            #content {
                >svg {
                    &:last-of-type {
                        width: 220px;
                        // transform: translateX(-25px) translateY(-15px);
                    }
                }
            }
        }
    }

    // @include break($medium, '<') {
    //     navigation-bar {
    //         top: 73px;
    //         right: 20px;
    //     }

    //     .loyc-logo {
    //         width: 220px;
    //     }
    // }

    // End refactor
    @include break($medium, '>') {

        navigation-bar {
            top: 55px;
            right: 0;
        }

        main {
            #content {
                // padding: 10px 100px 50px;

                application-form {
                    .inputs {
                        padding: 10px 0;
                        text-align: right;

                        &:first-of-type {}

                        &:last-of-type {
                            text-align: left;
                            margin-right: auto;
                            margin-left: $gap;
                        }
                    }

                    .checkboxes {
                        .inputs {
                            text-align: left;
                            max-width: calc(50% - #{$gap0});
                        }
                    }
                }

                .badges {
                    flex-direction: row;

                    >* {
                        margin-top: $gap2;
                    }
                }

                >svg {
                    &:first-of-type {
                        display: block;
                    }

                    &:last-of-type {
                        display: none;
                    }
                }

                // .loyc-logo {
                //     width: 700px;
                // }
            }
        }
    }

    @include break(930px, '>') {
        main {
            #content {
                .badges {
                    >* {
                        &+* {
                            margin-left: $gap2;
                        }
                    }
                }
            }
        }
    }

    @include break($large, '>') {
        main {
            #content {
                .badges {
                    >* {
                        margin-top: $gap3;

                        &+* {
                            margin-left: $gap4;
                        }
                    }
                }
            }
        }
    }
}

// CLASSES FOR UPDATED HOMEPAGE
.content-block {

    // Shared Styles
    h1,
    h2,
    h3 {
        user-select: none;
        letter-spacing: initial;
        // text-transform: uppercase;
        line-height: 1.4;
    }

    h3 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 1em;
    }

    p {
        line-height: 1.4;
        margin-bottom: 1em;

        &:last-of-type {
            margin-bottom: 0;
        }

        a:not(.button) {
            color: $orange;

            &:active,
            &:focus,
            &:hover {
                color: darken($orange, 10%);
            }
        }
    }

    header {
        text-align: center;
    }

    table {
        border-spacing: 4px;
        border-collapse: separate;
        max-width: 100%;
        line-height: 1.4;
        max-width: 100%;

        td,
        th {
            border-radius: 4px;
            padding: $gap;
        }

        tbody {
            p {
                text-align: left;
            }
        }
    }

    // Layout
    .col-layout {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        >div {
            width: 100%;
        }

        .w100 {
            overflow-x: auto;
        }
    }

    @media (min-width: $medium) {
        .col-layout {

            .w50g,
            >div {
                width: 48%;
            }

            .w100 {
                width: 100%;
            }
        }
    }
}

#caravan-bg {
    padding-bottom: 100px;
    background-image: url('/assets/images/motorhome.png?q=100&auto=format');
    background-repeat: no-repeat;
    background-size: 80% auto;
    background-position: center bottom;
    text-align: center;
    @include break($small, '>') {
        padding-bottom: 300px;
    }

    @include break($medium, '>') {
        padding-bottom: 400px;
    }

    @include break($large, '>') {
        padding-bottom: 520px;
    }
    .button{
        margin: 0 auto;
    }
}

#withdraw-equity-prestige {
    @extend .content-block;
    color: $mine-shaft;

    svg,
    img {
        max-height: 100px;
    }

    .wysiwyg-accordion {
        margin-top: 20px;

        >div {
            >div {
                background-color: $zumthor;
                padding: $gap2 $gap2 $gap;

                &+div {
                    margin-top: $gap;
                }
            }

            @include break($medium, '>') {
                display: grid;
                grid-template-columns: 50% 50%;
                grid-gap: $gap;

                >div {
                    &:last-child {
                        grid-column: span 2;
                    }
                    &+div {
                        margin-top: 0;
                    }
                }
            }
        }
        h4 {
            position: relative;
            padding-right: 1em;
            margin-top: 0;

            &:after {
                content: '';
                transform: translateY(-70%) rotate(135deg);
                height: 12px;
                width: 12px;
                border-right: 2px solid $bahama-blue;
                border-top: 2px solid $bahama-blue;
                transform-origin: center;
                right: 0;
                top: 50%;
                position: absolute;
                transition: transform 0.2s ease-in-out;
            }

            &:empty {
                display: none;
            }

            &.open {
                &:after {
                    transform: translateY(-30%) rotate(-45deg);
                }
            }
        }

        p {
            max-height: 0;
            margin: 0;
            opacity: 0;
            transition: max-height 0.2s ease-in-out 0.2s,
                margin 0.2s linear 0.2s,
                opacity 0.2s ease-in-out;

            &.open {
                overflow: hidden;
                max-height: 1000em;
                margin-bottom: revert;
                opacity: 1;
                transition: max-height 0.2s ease-in-out,
                    opacity 0.2s ease-in-out 0.2s;
            }
        }
    }

    // Overrides
    h3 {
        color: $bahama-blue;
        text-transform: initial;
        font-size: 36px;
        font-weight: 400;
        margin-top: 0.5em;
    }

    h4 {
        color: $bahama-blue;
        font-size: 18px;
        margin-top: 1em;
        line-height: 1.4;
    }

    p{
        color: $bahama-blue
    }

    .cta-container {
        margin-top: 1em;

        contact-details {
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 0;
        }
    }

    .col-layout {
        >div {
            width: 100%;
            margin-bottom: 1em;
            text-align: center;

            &:nth-child(odd) {
                p:first-of-type {
                    font-weight: 700;
                }
            }
        }
    }

    @include break($small-medium, '>') {
        .col-layout {
            >div {
                text-align: left;

                h3 {
                    text-align: center;
                }
            }
        }

        .wysiwyg-accordion {
            >div>div {
                position: relative;
                padding: $gap2 $gap2 $gap $gap5;

                &:before {
                    position: absolute;
                    left: $gap2;
                    content: '';
                    transform: rotate(45deg);
                    height: 1.5em;
                    width: 0.75em;
                    border-bottom: 6px solid $web-orange;
                    border-right: 6px solid $web-orange;
                }
            }

            h4 {
                padding-right: 0;
                text-align: left;

                &:after {
                    content: unset;
                }
            }

            p {
                max-height: unset;
                opacity: 1;
                margin-bottom: revert;
            }
        }
    }

    @include break($medium, '>') {
        .col-layout {
            >div {
                &:nth-child(even) {
                    >div {
                        column-count: 2;

                        >div {
                            break-inside: avoid;
                        }
                    }
                }
            }
        }
    }
}

#type-of-caravans-pawned {
    @extend .content-block;
    text-align: center;

    header {
        padding: 0 $gap;
    }

    h3 {
        color: $mantis;
        text-transform: initial;
        font-size: 26px;
        font-weight: 400;
    }

    .car-card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: $gap2;
        margin-top: $gap2;
        p{
            color: $bahama-blue;
        }

    }

    .car-card {
        cursor: pointer;
        background-color: $zumthor;
        width: 100%;
        // NOTE: Do not stretch siblings to fill height
        align-self: flex-start;

        &.open {
            >div {
                padding: $gap;
                overflow: hidden;
                max-height: 1000em;
                opacity: 1;
                transition: max-height 0.2s ease-in-out,
                    opacity 0.2s ease-in-out 0.2s;
            }
        }

        header {
            background-color: $white;
            padding: $gap2;

            svg,
            img {
                max-height: 75px;
                margin-bottom: $gap;
                max-width: 200px;
            }

            h4{
                color: $bahama-blue;
                text-transform: uppercase;
                font-weight: 400;
                font-size: 18px;
                margin: 0;
            }
        }

        >div {
            max-height: 0;
            padding: 0;
            opacity: 0;
            transition: max-height 0.2s ease-in-out 0.2s,
                padding 0.2s linear 0.2s,
                opacity 0.2s ease-in-out;
        }
    }

    .button {
        margin-top: $gap2;
    }

    contact-details {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    @include break($small-medium, '>') {
        header {
            padding: 0 $gap2;
        }

        .car-card {
            width: calc(50% - #{$gap});
        }
    }

    @include break($medium, '>') {

        h3 {
            font-size: 36px;
        }
    }

    @include break($large, '>') {

        .car-card {
            width: calc(33% - #{$gap2});
        }
    }
}


#pawn-your-caravan-faqs {
    padding: $gap2 0;

    h3 {
        color: $bahama-blue;
        font-size: 26px;
        font-weight: 400;
        text-transform: initial;
        text-align: center;
        margin-bottom: $gap2;
    }

    article.accordion {
        background-color: $zumthor;
        color: $bahama-blue;

        &+.accordion {
            margin-top: $gap;
        }

        .title {
            background-color: $mantis;

            &:after {
                border-right-color: $bahama-blue;
                border-top-color: $bahama-blue;
            }
        }
    }

    @include break($medium, '>') {
        padding: $gap6 0;

        h3 {
            font-size: 36px;
        }

        .accordion-container {
            max-width: 85%;
            margin: 0 auto;
        }
    }

    @include break($large, '>') {
        .accordion-container {
            max-width: 65%;
            margin: 0 auto;
        }
    }
}

#partner-referal-program-modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba($mine-shaft, 0.75);
    opacity: 0;
    visibility: hidden;
    transition: opacity .4s ease-in-out;

    &.show {
        opacity: 1;
        visibility: visible;
    }

    form {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 500px;
        width: 100%;
        background-color: $mine-shaft;
        border-radius: 4px;
        padding: $gap2;
        box-shadow: 0 0 10px rgba(black, 0.8);
        max-height: 85vh;
        overflow: scroll;

        h4 {
            text-align: center;
            color: $bahama-blue;
        }

        p {
            text-align: center;
            color: $mine-shaft;
        }
    }

    .form-group {
        margin-top: $gap;

        label {
            color: $mine-shaft;
        }

        input {
            margin-top: $gap;
            padding: 4px 6px
        }

        sup {
            color: $bahama-blue;
        }

        input[type="submit"] {
            background-color: $bahama-blue;
            color: $mine-shaft;
            text-transform: uppercase;
            padding: $gap;
            border-radius: 4px;
            font-weight: 700;
        }

        button {
            background-color: $dusty-gray;
            color: $mine-shaft;
            text-transform: uppercase;
            padding: $gap;
            border-radius: 4px;
            font-weight: 700;
            margin-left: $gap;
        }
    }

    .notices {
        margin-top: $gap;

        &:empty {
            display: none;
        }

        &.error {
            color: red;
        }

        &.success {
            color: $mantis;
        }

        p {
            color: inherit;
        }
    }
}
