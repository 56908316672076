$primary: #96c11f; $secondary: #29235c; $fonts: '/assets/fonts/'; $images: '/assets/images/'; $sprites: '/assets/images/sprites/';@import './src/vendor/ds/imports', 'settings';////////////////////////////////////////////////////////////////////////////////
// Global Styling
////////////////////////////////////////////////////////////////////////////////
// NOTE: This is the only way it works...
$slick-font-path: "../fonts/";
$slick-loader-path: "../images/";
@import "node_modules/slick-carousel/slick/slick.scss";
@import "node_modules/slick-carousel/slick/slick-theme.scss";

body {
    color: $bahama-blue;

    site-wrapper {
        display: block;
        // flex-direction:column;
        position: relative;
        min-height: 100vh;
        min-width: $min;
        width: 100%;
        margin: $sticky-height 0 auto;
        background-color: $white;
    }

    &.locked {

        // height: 100vh;
        site-wrapper {
            touch-action: none;

            // padding-right:var(scrollbar-width, 0);
            &:before {
                opacity: 1;
            }
        }

        @include devices(mobile tablet) {
            site-wrapper {
                height: inherit;
            }
        }
    }

    @include outside-break($min) {
        overflow-x: hidden;
    }

    &.dev-environment {
        @include show-breakpoints();
    }
}

h1 {
    font-weight: 700;
    margin-bottom: $gap;
    color: $text-colour;
    line-height: 1;
}

h2,
h3,
h4,
h5,
h6 {
    margin-bottom: $gap0;
    color: $text-colour;
    line-height: 1;
    text-transform: uppercase;
}

h1,
%h1 {
    @include responsive-fontsize($h1, $h2);
    font-weight: 600;
}

h2,
%h2 {
    @include responsive-fontsize($h2, $h3);
    font-weight: 600;
}

h3,
%h3 {
    @include responsive-fontsize($h3, $h4);
    font-weight: 600;
    text-transform: capitalize;
}

h4,
%h4 {
    @include responsive-fontsize($h4, $h5);
    font-weight: 600;
    text-transform: capitalize;
}

h5,
%h5 {
    @include responsive-fontsize($h5, $h6);
}

h6,
%h6 {
    @include responsive-fontsize($h6, $font-size);
}



q,
blockquote {
    quotes: "“""”""‘""’";

    &:before {
        content: open-quote;
    }

    &:after {
        content: close-quote;
    }
}

a {
    @include transition(color);
}

pre {
    background-color: white;
    color: black;
    text-transform: none;
    text-decoration: none;
    text-align: left;
    display: block;
    padding: $gap;
    position: relative;
    z-index: 999;
}

// =============================================================================
// Buttons
// =============================================================================

.button {

    --button-theme:#{var(primary)};

    &.blue {
        --button-theme : #{secondary};
    }

    position: relative;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    padding:0.5714em 0.8095em;
    text-transform: uppercase;
    font-size:21px;
    line-height:1;
    border:1px solid transparent;
    font-weight: 600;
    @include border-radius(5);

    // Standard Button Colours
    @include button($transitions : color background-color border-color,
        $initial:('background' : var(button-theme),
            'colour' : $white,
            'stroke' : $white,
            'fill' : $white,
            'border' : transparent,
        ),
        $hover:('background' : darken($bahama-blue, 10%),
            'colour' : $white,
            'stroke' : $white,
            'fill' : $white,
            'border' : var(button-theme),
            'important' : true),
    );

    &.blue {
        @include button($initial:('background' : var(secondary),
                'colour' : $white,
                'stroke' : transparent,
                'fill' : transparent,
                'border' : transparent),
            $hover:('background' : darken($blue1, 10%),
                'colour' : white,
                'stroke' : white,
                'fill' : white,
                'border' : transparent,
                'important' : true),
        );
    }

    &.lightblue {
        @include button($initial:('background' : $blue2,
                'colour' : $white,
                'stroke' : transparent,
                'fill' : transparent,
                'border' : transparent),
            $hover:('background' : darken($blue2, 10%),
                'colour' : white,
                'stroke' : white,
                'fill' : white,
                'border' : transparent,
                'important' : true),
        );
    }

    &.clear {
        @include button($initial:('background' : transparent,
                'colour' : $blue1,
                'stroke' : transparent,
                'fill' : transparent,
                'border' : $blue1),
            $hover:('background' : $blue2,
                'colour' : white,
                'stroke' : white,
                'fill' : white,
                'border' : transparent,
                'important' : true),
        );
    }

    &.small {
        padding: 10px;
        font-size: 15px;
    }

    svg {
        margin-left: $gap0;

        &[class*="arrow-"]:not([class*="arrow-left"]) {
            margin-left: $gap0 + 3;
        }

        &[class*="arrow-left"] {
            margin-left: 0;
            margin-right: $gap0 + 3;
        }

        @include transition(stroke fill);

 
    }

    &:hover svg[class*="arrow-"] {
        stroke: white;
    }
}

.buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    a:first-of-type {
        margin-right: 10px;
    }
}

// =============================================================================
// Error Messages
// =============================================================================

error-messages {
    display: block;
    width: 100%;
    height: auto;
    border: 2px solid red;
    border-radius: 6px;
    margin-bottom: $gap2;
    padding: $gap;
    background-color: $white;

    p {
        color: red;
        margin: 0 !important;
    }
}

input-fields {
    div.error {
        input {
            border: 1px solid red;
            color: red;
        }
    }

    ul.errors {
        list-style: none;
        color: red;
        text-align: left;
        margin-top: 2%;

        li {
            margin-top: 0;
            font-size: 0.75em;
        }
    }
}

// =============================================================================
// Error pages
// =============================================================================

body[id^="error-"] {
    main {
        text-align: center;

        h1,
        p {
            margin-bottom: 1em;
        }
    }
}

// =============================================================================
// Content and Aside
// =============================================================================

content {
    background: $grey;
    display: block;
}

content-aside {
    display: flex;
    flex-direction: column;
    background-color: $zumthor;

    #content {
        flex-grow: 5;

        p {
            line-height: 1.4;
        }

        p {
            margin-bottom: $gaps+px;
        }

        h2 {
            font-size: 22px;
            color: $bahama-blue;
            margin: $gaps*2+px 0 $gaps+px;
        }

        h3 {
            color: $bahama-blue;
            margin-bottom: $gaps+px;
        }

        ul {
            margin: $gap2;
            list-style: disc;

            li {
                margin: $gaps+px 0;
            }
        }

        ol {
            margin: $gap2;
            list-style: decimal;

            li {
                margin: $gaps+px 0;
            }

            // Mimic Redactor nested list styles
            ol li {
                list-style-type: lower-alpha;
            }
            ol ol li {
                list-style-type: lower-roman;
            }
        }
    }

    hr {
        opacity: 0.3;
        width: 80%;
        margin: 50px auto;
    }

    aside {
        //flex-grow: 1;
        width: 100%;
        background: $bahama-blue;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        contact-details {
            border-top: 1px solid $grey2;
            margin-bottom: 0;
            padding-bottom: 0;
            color: $white;
            p span {
                display: none;
            }
            a{
                color: $web-orange;
                &:hover{
                    color: $mantis;
                }
            }
        }
    }

    @media(min-width: $medium) {
        flex-direction: row;

        aside {
            width: 350px;
            min-width: 350px;
        }
    }
}

.quote-aside {

    padding: 30px 20px;
    text-align: center;
    position: sticky;
    top: $gap;

    input-fields {
        display: block;

        >div {
            max-width: 100%;
        }
    }

    h4 {
        text-transform: uppercase;
        font-size: 23px;
        line-height: 27px;
        margin: 0;
        letter-spacing: $letterspace;
        color: $white;
    }

    .submits {
        flex-direction: column;
        align-items: center;

        >input,
        .applyByPhone {
            max-width: 100%;
        }
    }
}

// Page Structure

content-aside {

    #content {
        padding: $gap2;
        background-color: $zumthor;
    }

    aside {
        h3 {
            display: block;
            background: $bahama-blue;
            padding: 30px;
            color: $white !important;
            margin-bottom: 0;
        }

        >ul {
            background: $mantis;
            margin: 0;

            li {
                display: block;
                position: relative;
                width: 100%;
                //line-height: 60px;
                padding-left: 60px;
                border-bottom: 1px solid $mine-shaft;
                transition: all .3s ease-in-out;

                a {
                    height: 60px;
                    display: flex;
                    width: 100%;
                    align-items: center;
                    color: $white;
                }

                &:before {
                    position: absolute;
                    left: 25px;
                    top: 20px;
                    background: $web-orange;
                    padding-left: 3px;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    content: "\203A";
                    font-size: 30px;
                    line-height: 15px;
                    text-align: center;
                    color: $white;
                    transition: all .3s ease-in-out;
                }

                &.current, &:hover {
                    background-color: lighten($mantis, 15);

                    a {
                        color: $white;

                    }

                    &:before {
                        background: $white;
                        color: $bahama-blue
                    }
                }
            }
        }

        application-form{
            input.large{
                background-color: $white;
                color: $bahama-blue;
                &::placeholder{
                    color: $bahama-blue;
                }
            }
            .submits .applyByPhone {
                color: $white;
                
                select{
                    border: 1px solid $white;
                    color: $white;
                }
            }
        }
    }
}

.brand-logo {
    display: block;
    width: 600px;
    max-width: 100%;
}

@include break($medium, '<') {

    header {
        .generic {
            a {
                &:last-of-type {
                    display: block;
                }
            }
        }
    }
}

@keyframes notificationSlider {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    75% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

// GLOBAL NOTIFICATION BAR
.notification-bar {
    padding: 8px;
    color: white;
    background-color: $blue;
    font-size: 14px;
    position: relative;
    // (font-size * line-height) + (2 * padding)
    min-height: calc((2em * 1.14) + (2 * 8px));

    @include break($small, '>') {
        font-size: 16px;
    }

    @include break($medium, '>') {
        font-size: 21px;
    }

    &~main {
        padding-top: 0;
    }

    &.animated {
        .notification {
            opacity: 0;
            animation: notificationSlider 8s infinite;

            // NOTE: Hard-coded for number of items
            @for $i from 1 through 2 {
                &:nth-child(#{$i}n) {
                    animation-delay: #{($i * 4) - 4}s;
                }
            }
        }
    }

    .notification {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        text-transform: uppercase;
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        max-width: 100%;
    }

    svg {
        height: 2em;
        width: 3.33333em;
        margin-right: 0.5em;
    }
}

// CHRISTMAS
.xmas {
    position: absolute;
    top: 0;
    width: 28%;
    z-index: 0;

    &.left {
        left: 0;
    }

    &.right {
        right: 0;
    }

    @include break($medium, '<') {
        width: 50%;

        &.right {
            display: none;
        }
    }
}
@import 'src/sass/components/accordion', 'src/sass/components/car-brands', 'src/sass/components/contact-details', 'src/sass/components/cta-panes', 'src/sass/components/forms', 'src/sass/components/how-it-works', 'src/sass/components/loan-features', 'src/sass/components/loyc-vs-competitors', 'src/sass/components/promos', 'src/sass/components/representative-example', 'src/sass/components/research-statement', 'src/sass/components/sticky-navigation', 'src/sass/components/switch-and-save', 'src/sass/components/tabs', 'src/sass/components/trustpilot', 'src/sass/components/unique-selling-points', 'src/sass/components/ver-comparison', 'src/sass/components/ver-guide', 'src/sass/components/ver-in-emergency', 'src/sass/components/video-flyout', 'src/sass/pages/complete', 'src/sass/pages/holding', 'src/sass/pages/how-it-works', 'src/sass/pages/index', 'src/sass/pages/introducer', 'src/sass/pages/landing-pages', 'src/sass/pages/news', 'src/sass/pages/reviews', 'src/sass/pages/switch-and-save', 'src/sass/pages/vehicle-equity-release', 'src/sass/sections/footer', 'src/sass/sections/header', 'src/sass/sections/main', 'src/sass/sections/navigation', 'src/sass/sections/sidebar';