.sticky-navigation {
    display: flex;
    flex-direction: row;
    background: $blue1;
    position: fixed;
    width: 100%;
    z-index: 999;
    text-transform: uppercase;
    font-weight: 700;
    top: 0;
    height: $sticky-height;
    background-color: $bahama-blue;

    .devices {
        height: 54px;
        width: 68px;
        margin-left: 12px;
    }

    p {
        color: $blue2;
        width: fit-content;
        display: none;
        position: absolute;
        white-space: pre-wrap;
        left: 66px;
        padding-left: 12px;

        &:before {
            content: "";
            height: 4px;
            width: 4px;
            background-color: $mantis;
            border-radius: 50%;
            left: -2px;
            display: block;
            position: absolute;
            z-index: 1000;
            top: calc(50% - 2px);
        }
    }

    &>ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex-grow: 1;

        li {
            display: none;
            justify-content: space-around;
            width: 25%;
            border-right: 1px solid white;
            transition: background-color .1s ease-in-out;
            height: $sticky-height;

            a {
                position: relative;
                display: block;
                text-align: center;
                align-items: center;
                height: 100%;
                display: flex;
                color: $white;
                padding: 8px;

                &[href*="loanonyourcar.com"] {
                    width: 100%;
                    // justify-content: flex-end;
                    color: white;
                    background-color: $green;
                }

                &[href*="pawnyourcar.co.uk"] {
                    // justify-content: flex-end;
                    color: $black;
                    background-color: $web-orange;
                }
            }

            &:last-of-type {
                // background-color: $web-orange;
                // border-right: 0px solid white;
                // max-width: 220px;
                align-items: center;
                border-right: 0;
                // padding-right: 3%;

                a {
                    // display: flex;
                    // height: 100%;
                    // padding: 0 12px 0 12px;
                    // color: $black;

                    // &:before,
                    // &:after {
                    //     display: block;
                    //     position: absolute;
                    // }

                    // &:before {
                    //     content: "NEW";
                    //     font-size: 8px;
                    //     z-index: 1001;
                    //     top: calc(50% - 5px);
                    //     left: 16px;
                    //     transform: rotate(-30deg);
                    //     transition: all .1s ease-in-out;
                    //     color: $white;
                    // }

                    // &:after {
                    //     content: "";
                    //     height: 30px;
                    //     width: 30px;
                    //     border-radius: 50%;
                    //     background-color: $blue1;
                    //     top: calc(50% - 15px);
                    //     left: 11px;
                    //     z-index: 1000;
                    // }
                }
            }

            &:hover {
                background-color: $bahama-blue;

                a {
                    &:before {
                        transform: rotate(0deg)
                    }
                }
            }
        }
    }

    .menu-trigger {
        width: 90px;
        height: 100%;
        background-color: $bahama-blue;

        svg {
            width: 30px;
            height: 30px;
            fill: $white;
            margin: 12px 30px;
        }

        navigation-bar {
            background-color: $mantis;
        }
    }

    @media (min-width: $min) {
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: flex-end;

        .devices {
            display: none;
        }

        p,
        svg {
            font-size: 14px;
            align-self: flex-start;
            left: 12px;
        }

        &>ul {
            display: flex;
            max-width: 40%;

            li {
                a {
                    font-size: 12px;
                }

                &:nth-last-child(-n+2) {
                    width: fit-content;
                    display: block;
                    max-width: unset;
                }
            }
        }
    }

    @media (min-width: $small) {
        .devices {
            display: block;
            margin: 0 8px;
            width: 54px;
        }

        p {
            font-size: 14px;
        }

        svg, p{
            left: 76px;
        }

        &>ul li a {
            font-size: 16px;
        }
    }

    @media (min-width: $medium + 1) {
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: nowrap;

        &>ul li a,
        p {
            font-size: 10px;
            align-self: center;
            padding: 6px;
        }

        p {
            padding: 0 12px 0 14px;
            // &:last-of-type{
            //     padding: 0 0 0 12px;
            //     &:before{
            //         content: "";
            //         height: 4px;
            //         width: 4px;
            //         background-color: white;
            //         border-radius: 50%;
            //         left: -2px;
            //         display: block;
            //         position: absolute;
            //         z-index: 1000;
            //         top: calc(50% - 2px);
            //     }
            // }
        }

        &>ul {
            display: flex;
            max-height: $sticky-height;
            max-width: 35%;
            margin-left: auto;

            li {
                display: flex;
                width: 20%;

                &:last-of-type {
                    width: fit-content;

                    // a {
                    //     padding: 0 0 0 48px;
                    // }
                }
            }
        }
    }

    @media (min-width: 1000px) {

        &>ul li a,
        p {
            font-size: 13px;
        }

        p {
            padding: 0 16px 0 18px;
            min-width: 240px;
            font-size: 18px;

            // &:last-of-type {
            //     padding: 0 18px 0 16px;
            // }
        }
    }

    @media (min-width: $large) {
        p {
            padding: 0 16px 0 18px;
            min-width: 240px;

            // &:last-of-type {
            //     padding: 0 18px 0 16px;
            // }
        }
    }

    @media (max-width: $medium) {
        .menu-trigger {
            width: 50px;

            svg {
                margin: 12px;
            }
        }

        navigation-bar {
            &>a {
                width: 49px;
            }
        }

        &>ul {
            position: absolute;
            right: 0;
            margin-right: 50px;
        }
    }

    @media (max-width: $small-medium) {
        p {
            max-width: 35%;
        }

        .menu-trigger {
            width: 50px;

            svg {
                margin: 12px;
            }
        }

        navigation-bar {
            &>a {
                width: 49px;
            }
        }

        &>ul {
            position: absolute;
            right: 0;
            margin-right: 50px;

            // li:last-of-type a {
            //     padding: 0 6px;
            //     max-width: 90px;

            //     &::before,
            //     &::after {
            //         display: none;
            //         content: none;
            //     }
            // }
        }
    }
}
