#vehicle-equity-release-comparison {
    @extend .content-block;

    header {
        padding: $gap $gap 0;

        h1 {
            font-size: 24px;
            font-weight: 100;
            color: $blue;
            margin: 0;
        }
    }

    table {
        td,
        th {
            width: 33%;
        }
        th {
            background-color: $blue;
            text-align: left;

            & + th {
                background-color: $orange;
            }
        }
        td {
            color: $blue1;
            font-size: 14px;
            p {
                font-size: inherit;
                margin-bottom: 1em;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
        thead {
            color: $white;
            text-transform: uppercase;
            font-size: 21px;
        }
        tbody {
            background-color: lighten($orange, 38%);

            th {
                background-color: lighten($blue, 55%);
                color: $blue2;
            }
        }
    }

    // Overrides
    .col-layout {
        padding: 0 $gap $gap;

        > div {
            margin-top: $gap;

            &:last-of-type p {
                text-align: center;
            }
        }
    }

    @media (min-width: $medium) {
        header {
            padding: $gap4 $gap4 0;

            h1 {
                font-size: 30px;
            }
        }

        .col-layout {
            padding: 0 $gap3 $gap3;

            > div {
                margin-top: $gap2;
            }
        }
    }
}
