#news,
.news {
    ._index {
        #content {
            padding: 20px;

            .articles {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-top: $gaps*2+px;

                > article {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;
                    margin: 0 0 20px;
                    background: $white;
                    border-radius: 15px;
                    overflow: hidden;

                    > a,
                    news-image {
                        display: block;
                        width: 100%;
                        min-height: 250px;
                        overflow: hidden;
                    }

                    news-image {
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center center;
                        height: 100%;
                    }

                    > div {
                        padding: 30px;
                        width: 100%;
                    }

                    post-date {
                        display: inline-block;
                        float: right;
                        margin-top: 2px;
                    }

                    h3 {
                        line-height: 1.4;
                        letter-spacing: normal;
                    }

                    small {
                        display: inline-block;
                        padding: 5px 10px;
                        border-radius: 15px;
                        text-transform: uppercase;
                        margin: 0px 8px 8px 0px;
                        font-size: 12px;

                        a {
                            color: white;
                        }

                        &.blue {
                            background: $blue2;
                            color: $white;
                        }
                    }

                    img {
                        margin-bottom: $gaps*2+px;
                    }

                    > article-overlay {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        background: rgba(#262262, 0.9);
                        transition: 300ms;
                        opacity: 0;
                        pointer-events: none;
                    }

                    &:hover {
                        article-overlay {
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                }
            }
        }
        @include break($large, '>') {
            #content {
                padding: 40px;

                .articles {
                    > article {
                        width: calc(50% - 20px);
                        margin: 0 20px 20px 0;

                        news-image {
                            max-height: 25px;
                        }

                        &:first-of-type {
                            flex-direction: row;
                            width: 100%;

                            a {
                                height: 100%;
                            }

                            news-image {
                                width: 100%;
                                height: 100%;
                                max-height: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &.entry {
        content-aside {
            #content {
                padding: $gaps*2+px;
                background: none;
                text-align: justify;

                h3 {
                    margin-top: 1em;
                }

                img {
                    width: 100%;
                }

                > figure,
                > img {
                    margin-bottom: 1em;
                }
                table {
                    width: 100%;

                    th, td {
                        border: 1px solid #ddd;
                        padding: 8px;
                    }
                }

                p {
                    &:empty {
                        display: none;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    // Components
    .social-share-links {
        margin: $gap 0;

        ul {
            margin: 0;
            list-style: none;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            li {
                margin: 0;

                & + li {
                    margin-left: 1em;
                }
                a {
                    display: block;
                    line-height: 0;
                }

                svg {
                    width: 30px;
                    height: 30px;
                    transition: fill 0.2s ease-in-out;

                    &.facebook {
                        fill: $facebook;
                    }
                    &.twitter {
                        fill: $twitter;
                    }
                    &.linkedin {
                        fill: $linkedin;
                    }
                    &:hover, &:active, &:focus {
                        &.facebook {
                            fill: darken($facebook, 15%);
                        }
                        &.twitter {
                            fill: darken($twitter, 15%);
                        }
                        &.linkedin {
                            fill: darken($linkedin, 15%);
                        }
                    }
                }
            }
        }
    }
}
