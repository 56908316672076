////////////////////////////////////////////////////////////////////////////////
// Header
////////////////////////////////////////////////////////////////////////////////

site-wrapper {
    header.default {
        // Default container styling to include a `navigation-bar`
        position: relative;
        max-width: $max;
        margin: 0 auto;
    }

    header.generic {
        position: relative;
        max-width: $max;
        display: block;
        margin: 0 auto;
        height: 120px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0 15px;
        margin-top: 10px;

        > p {
            display: none;
            @extend .bold;
            font-size: 21px;
            margin-top: 0;

            > a {
                color: $grey1;
                white-space: nowrap;

                &:first-of-type {
                    color: $bahama-blue;
                    margin-right: 20px;
                }

                &:last-of-type {
                    color: $bahama-blue;
                    margin-right: 10px;
                }

                &:hover {
                    color: $bahama-blue;
                }


                > svg {
                    height: 21px;
                    width: 21px;
                    transform: translateY(2px);
                    margin-right: 8px;
                    fill: $web-orange;
                }

                > span {
                    color: $mantis;
                }
            }

        }

        > a {
            text-align: right;
            padding: 10px;
            margin-right: 0;
            margin-top: 1px;
            > svg {
                fill: $mantis;
                width: 30px;
                height: 30px;
            }
        }

        > div {
            margin-right: auto;

            > a {
                &:first-of-type {
                    display: block;

                    > svg {
                        width: 250px;
                    }
                }

                &:last-of-type {
                    display: none;
                }
            }
        }
    }
    @include break($small, '>') {
        header.generic {
            padding: 0 $gap1;
        }
    }
    @include break($medium, '>') {
        header.generic {
            padding: 0 $gap2;

            > p {
                display: inline-block;
            }

            > div {
                > a {
                    &:first-of-type {
                        display: none;
                    }

                    &:last-of-type {
                        display: block;

                        > svg {
                            width: 445px;
                            margin-top: 18px;
                        }
                    }
                }
            }
        }
    }
    @include break(1000px, '>') {
        header.generic {
            > p {
                br {
                    display: none;
                }
            }
        }
    }
    @include break($large, '>') {
        header.generic {
            > div {
                > a {
                    &:last-of-type {
                        display: block;

                        > svg {
                            width: 550px;
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}
